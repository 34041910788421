<script setup lang="ts">
import {store} from '../store/Store';
import {onMounted, ref, watch} from "vue";
import {loaders} from "../store/Loaders";

const supportedChains = ref([]);
const showChainList = ref(false);
const selectedNetworkChainId = ref("");
const selectedNetwork = ref("");

const previousSelectionChainId = ref("");

const supportedChainsStyle = store.useNarrowLayout ? "supportedChainsMobile" : "supportedChainsDesktop";

const showSupportedChainList = () => {
  if (store.backendInfo && store.walletIsConnected) {
    if (supportedChains.value.length <= 0) {
      supportedChains.value = (store.backendInfo as any).supportedChains.map(c => ({
        label: c.label,
        value: c.chainId,
        rpcUrl: c.rpcUrl
      }));
      setCurrentNetworkChain();
    } else {
      if (!showChainList.value) {
        showChainList.value = true;
        setCurrentNetworkChain();
      }
    }
  }
}
const setCurrentNetworkChain =function(){
    const currentState = store.web3Onboard.state.get();
    const chainId = currentState.wallets?.[0]?.chains?.[0]?.id;
    const item =  supportedChains.value.find(item => item.value === chainId);
    if (item && item.label && item.value) {
      selectedNetworkChainId.value = chainId;
      previousSelectionChainId.value = chainId;
      selectedNetwork.value = item.label.toLowerCase();
      store.chainToUse = item.label?.toUpperCase();
    }else{
      supportedChains.value.push({
        label: "Please select network",
        value: "0*0",
        rpcUrl: ""
      });
      selectedNetworkChainId.value = "0*0";
      previousSelectionChainId.value = "0*0";
    }

}

onMounted(() => {
  watch(() => store.backendInfo, showSupportedChainList);
  watch(() => store.walletIsConnected, () => {
    if (store.walletIsConnected) {
      showChainList.value = true;
      showSupportedChainList();
    } else {
      showChainList.value = false;
      supportedChains.value = [];
    }
  });
});

const handleChange = async (e) => {
  switchNetwork(e.target.value, e.target.options[e.target.options.selectedIndex].text);
}

const switchNetwork = async (supportedChainId,supportedChain) => {
  try {
    const chainInfo = supportedChains.value.find(chain => chain.value === supportedChainId);
    if (chainInfo) {
      const { label: chainToUse, rpcUrl } = chainInfo;
      const success = await store.web3Onboard.setChain({
        chainId: supportedChainId,
        rpcUrl: rpcUrl
      });

      if (success) {
        previousSelectionChainId.value = supportedChainId;
        selectedNetworkChainId.value = supportedChainId;
        selectedNetwork.value = supportedChain?.toLowerCase();
        store.dataProvider = null;
        store.chainToUse = chainToUse;
        store.isWhitelisted = false;
        loaders.loadInstruments();
      } else {
        if (!previousSelectionChainId.value) {
          setCurrentNetworkChain();
        }
        else {
          selectedNetworkChainId.value = previousSelectionChainId.value;
        }
      }
      console.log("switchNetwork setChain", success);
    }
  } catch (error) {
    if (!previousSelectionChainId.value) {
      setCurrentNetworkChain();
    }
    else {
      selectedNetworkChainId.value = previousSelectionChainId.value;
    }
    console.error("switchNetwork error while set chain", error);
  }
}
</script>

<template>
  <div v-if="showChainList" class="o-ctrl-sel">
    <span class="o-icon o-sel__icon-left" data-oruga="icon">
      <img v-bind:src="'/crypto-icons/'+selectedNetwork.valueOf()+'.svg'" alt="" class="alice-crypto-icon-small"/>
    </span>
    <select v-model="selectedNetworkChainId" @change="handleChange" class="alice-select supportedChains" :class="supportedChainsStyle">
      <option v-for="item in supportedChains" :value="item.value" :key="item.value">
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<style>

.o-ctrl-sel {
    display: inline-flex;
    float: right;
}

.alice-crypto-icon-small {
  height: 20px;
}

.supportedChains {
  padding: 4px 20px 10px 30px;
  float: right;
  font-size: 13px;
}

.supportedChainsDesktop {
  margin: 0px 64px 0px 0px;
}

.supportedChainsMobile {
  margin: 0px 0px 0px 0px;
}

</style>