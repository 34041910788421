import {nextTick} from 'vue'

import "@ethersproject/shims"
import {Contract, ethers, utils} from 'ethers';
import {v4 as uuidv4} from 'uuid';

import {init} from '@web3-onboard/vue'
import injectedModule from '@web3-onboard/injected-wallets'

import {store} from '../store/Store';
import {wsloaders} from '../store/Websocket';
import {reloadHistoryFromApi} from "../store/OrderHistoryManager";
import type {OnboardAPI} from '@web3-onboard/core';
import type {WalletConnectOptions} from "@web3-onboard/walletconnect/dist/types";
import walletConnect from "@web3-onboard/walletconnect";
import {useOrderHistoryStore} from "../store/OrderHistory";
import {hasOnboardEvents, jwtsAreValid, setOnboardEvents, signInWithEthereumAll, signOutAndClearJWT, siweTokenIsValid, siweUserEquals,} from "./SiweClient";
import {sendWithdrawalRequest, WithdrawalRequest} from "./RestApiClient";
import {ERC20ABI} from "./StandardAbis";
import BigNumber from "bignumber.js"
import {getLeveragePositions} from "./LeveragePositions";

const DAPP_NAME = 'Alice'
const DAPP_DESC = 'Project Alice - Centralised liquidity, decentralised security'
const API_KEY = 'feb705c9-5076-49ea-bac0-fe8bea57e600'

export const ORDER_SOURCE_FROM_CHAIN_ID: object = {
  1: "ETHEREUM",
  5: "GOERLI",
  10: "OPTIMISM",
  11155420: "OPSEP",
  11155111: "SEPOLIA"
} as const

export async function initWalletConnection()
{
  //signOutAndClearJWT();
  const injected = injectedModule()

  const chainInfo = (store.backendInfo as any).supportedChains ?? [];
  console.log('chains', chainInfo);

  const options: WalletConnectOptions = {
    projectId: 'e10c09f028fdc073c48cd84f275d45e1',
    requiredChains: chainInfo.map(chain => Number(chain.chainId)),
    optionalChains: [],
    dappUrl: 'https://' + window.location.host
  }

  const web3Onboard: OnboardAPI = init({
    apiKey: API_KEY,
    appMetadata: {
      name: DAPP_NAME,
      description: DAPP_DESC,
      icon: 'alice-icon-mobile.svg',
      recommendedInjectedWallets: [
        {name: 'MetaMask', url: 'https://metamask.io'},
        {name: 'WalletConnect', url: 'https://walletconnect.com'}
      ]
    },
    connect: {
      showSidebar: true,
      autoConnectLastWallet: jwtsAreValid() || false
    },
    wallets: [injected, walletConnect(options)],
    chains: chainInfo.map(chain => {
      return {
        id: chain.chainId,
        token: chain.token,
        label: chain.label,
        rpcUrl: chain.rpcUrl
      }
    })
  });

  if (store.useNarrowLayout)
  {
    web3Onboard.state.actions.updateAccountCenter({
      position: 'topLeft',
      enabled: true,
      minimal: true
    })
  }
  else
  {
    web3Onboard.state.actions.updateAccountCenter({
      position: 'topRight',
      enabled: true,
      minimal: true
    })
  }

  store.web3Onboard = web3Onboard;
  await wsloaders.subscribeMessages();
  if (!store.onboardPoller)
  {
    store.onboardPoller = setInterval(pollWallets, store.pollFreq);
  }
  pollWallets();
}

export async function verifyUserIsWhitelisted(wallet: any, dataProvider: any)
{
  const chainList: [any] = (store.backendInfo as any).supportedChains;

  if (store.isWhitelisted)
  {
    return;
  }

  let availableChain: any;
  chainList.forEach(chain => {
    if (chain.label.toUpperCase() == store.chainToUse.toUpperCase())
    {
      availableChain = chain;
    }
  });

  const chain: number = chainList.indexOf(availableChain);

  const whitelistContractAddress: string = (store.backendInfo as any).supportedChains[chain].whitelistSmartContractAddress;

  const whitelistAbi: string = (store.backendInfo as any).supportedChains[chain].whitelistABI;

  const sc: Contract = new Contract(whitelistContractAddress, whitelistAbi, dataProvider);

  store.isWhitelisted = await sc.verifyWhitelistedUser(wallet.accounts[0].address);
}

export async function getTokenBalance(token: string, dataProvider, user: string): Promise<BigNumber>
{
  let bal = 0;
  if (store.tokenAddresses[token] == '0x0000000000000000000000000000000000000000')
  {
    bal = await dataProvider.getBalance(user);
  }
  else
  {
    const sc: Contract = new Contract(store.tokenAddresses[token], ERC20ABI, dataProvider);
    bal = await sc.balanceOf(user);
  }
  // The slice stops random rounding bugs in the ui where the balance is more than you have, It seems to handle 17 SF fine, but no more, 18 allows for the decimal point
  return new BigNumber(utils.formatUnits(bal, store.tokenDPs[token]).slice(0, 18));
}

export async function getTokenAddressBalance(tokenAddress: string, tokenDp: number, dataProvider, user: string): Promise<BigNumber>
{
  const sc: Contract = new Contract(tokenAddress, ERC20ABI, dataProvider);
  const bal = await sc.balanceOf(user);
  return new BigNumber(utils.formatUnits(bal, tokenDp).slice(0, 18));
}

export function getDepositBalance(token: string): BigNumber
{
  const balances = store.restBalances;
  let tokenAddress = store.tokenAddresses[token];
  if (tokenAddress == '0x0000000000000000000000000000000000000000')
  {
    const chainInfo = store.getChainInfo();
    tokenAddress = chainInfo.wethContractAddress;
  }
  for (let i = 0; i < balances.length; i++)
  {
    const balance = balances[i];
    if (balance.tokenAddress.toLowerCase() == tokenAddress.toLowerCase())
    {
      return new BigNumber(balance.balance);
    }
  }
  return new BigNumber(0);
}

async function waitForConditionThen(condition: () => Promise<boolean>, secondsToWait: number, thingToRun: () => void)
{
  if (await condition())
  {
    console.log("Condition is true, will run");
    thingToRun();
  }
  else
  {
    if (secondsToWait > 0)
    {
      setTimeout(() => {
        waitForConditionThen(condition, secondsToWait - 1, thingToRun);
      }, 1000);
    }
    else
    {
      console.log("Given up waiting for condition");
    }
  }
}

export async function depositTokens(token: string, signer, amountToSend: BigNumber, user: string)
{
  let amountEncoded: ethers.BigNumber = utils.parseUnits(amountToSend.toFixed(store.tokenDPs[token]), store.tokenDPs[token]);
  console.log("Deposit tokens ", token, "amount ", amountEncoded);

  const chainInfo = store.getChainInfo();
  const dtwAddress = chainInfo.dtwContractAddress;
  const dtwContract: Contract = new Contract(dtwAddress, chainInfo.dtwABI, signer);
  if (store.tokenAddresses[token] == '0x0000000000000000000000000000000000000000')
  {
    const tx = await dtwContract.deposit(store.tokenAddresses[token], amountEncoded, {value: amountEncoded});
    console.log("tx:", tx);
  }
  else
  {
    const tokenContract: Contract = new Contract(store.tokenAddresses[token], ERC20ABI, signer);
    const allowanceAmount = await tokenContract.allowance(user, dtwAddress);
    if (amountEncoded.gt(allowanceAmount))
    {
      console.log("Need to approve allowance");
      await tokenContract.approve(dtwAddress, utils.parseUnits("999999999999", store.tokenDPs[token]));
    }
    await waitForConditionThen(
      async () => amountEncoded.lte(await tokenContract.allowance(user, dtwAddress)),
      30,
      async () => {
        const tx = await dtwContract.deposit(store.tokenAddresses[token], amountEncoded);
        console.log("tx:", tx);
      });
  }
}

export async function withdrawTokens(token: string, walletProvider, amountToWithdraw: BigNumber, user: string)
{
  const signer = walletProvider.getUncheckedSigner();

  let amountEncoded: ethers.BigNumber = utils.parseUnits(amountToWithdraw.toFixed(store.tokenDPs[token]), store.tokenDPs[token]);

  const chainInfo = store.getChainInfo();
  let chainId = Number(chainInfo.chainId);

  const dtwAddress = chainInfo.dtwContractAddress;

  const deadline = (+Date.now()) + 3600; // TODO: Put an actual deadline

  const domain = {
    name: "DTW",
    version: "1",
    chainId: chainId,
    verifyingContract: dtwAddress,
  }
  const types = {
    Withdraw: [
      {name: "user", type: "address"},
      {name: "token", type: "address"},
      {name: "amountToWithdraw", type: "uint256"},
      {name: "deadline", type: "uint256"}
    ]
  }
  let tokenAddress = store.tokenAddresses[token];
  const values = {
    user: user,
    token: tokenAddress,
    amountToWithdraw: amountEncoded,
    deadline: deadline
  }

  const sig = await signer._signTypedData(domain, types, values);

  const content: WithdrawalRequest = {
    "instructionId": uuidv4(),
    "orderSource": ORDER_SOURCE_FROM_CHAIN_ID[chainId],
    "tokenAddress": tokenAddress,
    "amount": amountToWithdraw.toString(),
    "deadline": deadline.toString(),
    "signature": sig
  }
  const res: Response = await sendWithdrawalRequest(content);
  console.log(res);
}

export async function getTokenAllowance(token: string, dataProvider, user: string, spender: string): Promise<BigNumber>
{
  try
  {
    const sc: Contract = new Contract(store.tokenAddresses[token], ERC20ABI, dataProvider);
    let allow = await sc.allowance(user, spender);
    return new BigNumber(utils.formatUnits(allow, store.tokenDPs[token]));
  }
  catch (e)
  {
    console.log("Failed to get token allowance for token ", token, " with address ", store.tokenAddresses[token], " spender ", spender)
  }
}

function isNativeEth(token: string)
{
  return store.tokenAddresses[token] == '0x0000000000000000000000000000000000000000';
}

export async function pollWallets()
{
  if (!store.pausePolling)
  {
    store.onboardPollCount++;
    const currentState = store.web3Onboard.state.get()
    const wallets = currentState.wallets;
    if (wallets)
    {
      if (!jwtsAreValid())
      {
        signOutAndClearJWT('SIWE token expired; pollWallets');
      }
      let wasConnected: boolean = store.walletIsConnected;
      if (!wasConnected && wallets.length > 0)
      {
        const wallet = wallets[0];
        if (!siweTokenIsValid())
        {
          await signInWithEthereumAll(wallet);
        }
        wsloaders.subscribeOrderUpdates();
        reloadHistoryFromApi();
        store.walletIsConnected = true;
      }
      if (store.walletIsConnected)
      {
        const wallet = wallets[0];
        const dataProvider = store.getDataProvider(wallet);
        const chainInfo = store.getChainInfo();

        verifyUserIsWhitelisted(wallet, dataProvider);

        const orderSource = ORDER_SOURCE_FROM_CHAIN_ID[Number(chainInfo.chainId)];
        await store.storeRestBalances(orderSource)

        store.frontBalance = await getTokenBalance(store.frontToken, dataProvider, wallet.accounts[0].address);
        store.frontDtwBalance = getDepositBalance(store.frontToken);

        if (store.backToken.toLowerCase() != store.backToken)
        {
          store.backBalance = await getTokenBalance(store.backToken, dataProvider, wallet.accounts[0].address);
          store.backDtwBalance = getDepositBalance(store.backToken);
        }
        else
        {
          store.backBalance = new BigNumber(0);
          store.backDtwBalance = new BigNumber(0);
        }

        if (!isNativeEth(store.frontToken))
        {
          if (store.leverage > 1)
          {
            store.frontAllowance = await getTokenAllowance(store.frontToken, dataProvider, wallet.accounts[0].address, chainInfo.leverageSmartContractAddress);
          }
          else
          {
            store.frontAllowance = await getTokenAllowance(store.frontToken, dataProvider, wallet.accounts[0].address, chainInfo.smartContractAddress);
          }
        }
        store.leveragePositions = await getLeveragePositions();

        if (store.tokenAddresses[store.backToken] == '0x0000000000000000000000000000000000000000')
        {
          getTokenAddressBalance(chainInfo.wethContractAddress, 18, dataProvider, chainInfo.liquidityPoolSmartContractAddress)
            .then((value: BigNumber) => {
              store.liquidityBalances[store.backToken] = value;
            });
        }
        else
        {
          getTokenBalance(store.backToken, dataProvider, chainInfo.liquidityPoolSmartContractAddress)
            .then((value: BigNumber) => {
              store.liquidityBalances[store.backToken] = value;
            });
        }

      }
    }
    else
    {
      store.walletIsConnected = false;
      store.isWhitelisted = false;
    }
  }
}

export async function setAllowance(targetToken: string, desiredAllowance: number, smartContractAddress: string)
{
  let didSet: boolean = false;
  if (store.tokenAddresses[targetToken] != '0x0000000000000000000000000000000000000000')
  {
    const currentState = store.web3Onboard.state.get()
    const wallets = currentState.wallets;
    const chainInfo = store.getChainInfo();

    if (wallets.length > 0 && chainInfo)
    {
      const wallet = wallets[0];

      for (let token in store.tokenAddresses)
      {
        if (token == targetToken)
        {
          const sc: Contract = new Contract(store.tokenAddresses[token], ERC20ABI, store.getDataProvider(wallet));
          let allow = await sc.allowance(wallet.accounts[0].address, smartContractAddress);
          let allowance: number = +(utils.formatUnits(allow, store.tokenDPs[token]));
          if (allowance < desiredAllowance)
          {
            const signer = store.getWalletProvider(wallet).getUncheckedSigner();
            const scWithSigner: Contract = new Contract(store.tokenAddresses[token], ERC20ABI, signer);
            const newAllowanceStr: string = "1000000"; //desiredAllowance.toString();
            const newAllowance: ethers.BigNumber = utils.parseUnits(newAllowanceStr, store.tokenDPs[token]);
            console.log('>> will call approve', smartContractAddress, newAllowance.toString());
            let result = await scWithSigner.approve(smartContractAddress, newAllowance);
            console.log('approve: result=', JSON.stringify(result));
            didSet = true;
          }
          break;
        }
      }
    }
    else
    {
      console.log('no transaction; no wallets/chains', wallets.length, chainInfo.length);
    }
  }
  return didSet;
}

export async function connectWallet()
{
  await store.web3Onboard['connectWallet']();
  subscribeOnboard();
}

export function subscribeOnboard()
{
  function disconnectCurrentUser()
  {
    store.walletIsConnected = false;
    store.isWhitelisted = false;
    signOutAndClearJWT('SIWE token expired; pollWallets');
    const orderHistory = useOrderHistoryStore();
    orderHistory.orders = [];
  }

  // TODO: Make SIWE store aware of REST API SIWE store
  if (store.web3Onboard && !hasOnboardEvents())
  {
    const wallets = store.web3Onboard.state.select('wallets');
    setOnboardEvents(wallets.subscribe((update) => {
      if (update && update.length > 0 && update[0].accounts && update[0].accounts.length > 0)
      {
        if (siweUserEquals(update[0].accounts[0].address))
        {
          //same user do nothing
        }
        else
        {
          disconnectCurrentUser();
        }
      }
      else
      {
        disconnectCurrentUser();
      }
    }));
  }
}
