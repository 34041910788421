import {reactive} from 'vue'
import {store} from './Store';
import {handleWebsocketUpdate} from "./OrderHistoryManager";
import {getSiweJwt, jwtsAreValid} from "../lib/SiweClient";
import BigNumber from "bignumber.js";
import {ethers} from "ethers";


function handler(event)
{
    try
    {
        let json = JSON.parse(event.data);
        wsloaders.handleMessage(json);
    }
    catch (e)
    {
        console.error(e);
    }
}

export const wsstore = reactive({
    socketsConnected: false,
    socket: null,
    timer: null,
    subscribedInstrument: ''
});

const ORDER_UPDATES = 'ORDER_UPDATES';
const PRICE_FEED_UPDATES = 'PRICE_FEED_UPDATES';
export const wsloaders = reactive({
    async subscribeMessages()
    {
        function removeWebsocketAndListeners()
        {
            if (wsstore.socket != null)
            {
                wsstore.socketsConnected = false;
                wsstore.socket.removeEventListener('open', handler);
                wsstore.socket.removeEventListener('close', handler);
                wsstore.socket.removeEventListener('error', handler);
                wsstore.socket.removeEventListener('message', handler);
                wsstore.socket.close();
                wsstore.socket = null;
            }
        }

        function addTimeoutToResubscribe()
        {
            if (wsstore.timer == null)
            {
                wsstore.timer = setTimeout(() => wsloaders.subscribeMessages(), 1000);
            }
        }

        if (!wsstore.socketsConnected)
        {
            removeWebsocketAndListeners();
            let wsUrl = store.wssUrl;
            if (wsUrl)
            {
                console.log('subscribeMessages: connecting to websocket', wsUrl);
                wsstore.socket = new WebSocket(wsUrl);
                wsstore.socket.addEventListener('message', handler);
                wsstore.socket.addEventListener("error", (error) => {
                    console.log('websocket error @', new Date(), error.message);
                    console.error(error);
                    removeWebsocketAndListeners();
                    addTimeoutToResubscribe();
                });
                wsstore.socket.addEventListener("close", () => {
                    console.log('websocket closed @', new Date());
                    removeWebsocketAndListeners();
                    addTimeoutToResubscribe();
                });
                wsstore.socket.addEventListener("open", (event) => {
                    console.log('websocket opened @', new Date());
                    wsstore.socketsConnected = true;
                    wsstore.timer = null;
                    this.subscribeOrderUpdates();
                    this.subscribePrices(store.instrumentPairs[store.frontToken + "/" + store.backToken].id);
                });
            }
        }
    },
    subscribeOrderUpdates()
    {
        if (jwtsAreValid())
        {
            const chainInfo = store.getChainInfo();

            let subscribe = {
                "type": "SUBSCRIBE",
                "orderSource": chainInfo.label,
                "channels": [
                    {"type": ORDER_UPDATES, "metadata": {"authorization": getSiweJwt()}}
                ]
            }
            wsloaders.sendMessage(subscribe);
            console.log('websocket subscribed for order updates', chainInfo.label, '@', new Date());
        }
    },
    subscribePrices(instrumentID: string)
    {
        if (wsstore.subscribedInstrument && wsstore.subscribedInstrument != instrumentID)
        {
            this.unsubscribePrices(wsstore.subscribedInstrument);
        }
        wsstore.subscribedInstrument = instrumentID;
        if (wsstore.socketsConnected && wsstore.subscribedInstrument)
        {
            const chainInfo = store.getChainInfo();
            let subscribe = {
                "type": "SUBSCRIBE",
                "orderSource": chainInfo.label,
                "channels": [
                    {"type": PRICE_FEED_UPDATES, "metadata": {"instrumentId": wsstore.subscribedInstrument}}
                ]
            }
            wsloaders.sendMessage(subscribe);
            console.log('websocket subscribed for prices', chainInfo.label, 'instrument', wsstore.subscribedInstrument, '@', new Date());

        }
    },
    unsubscribePrices(instrumentID: string)
    {
        if (wsstore.socketsConnected && instrumentID)
        {
            const chainInfo = store.getChainInfo();
            let subscribe = {
                "type": "UNSUBSCRIBE",
                "orderSource": chainInfo.label,
                "channels": [
                    {"type": PRICE_FEED_UPDATES, "metadata": {"instrumentId": instrumentID}}
                ]
            }
            wsloaders.sendMessage(subscribe);
            console.log('websocket unsubscribed from prices', chainInfo.label, 'instrument', instrumentID, '@', new Date());

            wsstore.subscribedInstrument = null;
        }
    },
    handleMessage(message)
    {
        if (message)
        {
            if (message.type == ORDER_UPDATES)
            {
                console.log('ORDER_UPDATES: ' + JSON.stringify(message));
                let order = message.order;
                handleWebsocketUpdate(
                    ethers.BigNumber.from(order.orderId),
                    {
                        orderSource: order.orderSource,
                        smartContractAddress: "unknown"
                    },
                    order.instrumentId,
                    order.side,
                    new BigNumber(order.quantitySold),
                    new BigNumber(order.limitAmount),
                    new BigNumber(order.quantityObtained),
                    order.timestamp,
                    order.status,
                    order.placeTransactionId,
                    order.instructionId,
                    order.settleTransactionId,
                    order.refundTransactionId,
                    new BigNumber(order.feePaid)
                );
            }
            else if (message.type == PRICE_FEED_UPDATES)
            {
                let p = message.priceFeed;
                store.updatePriceFromWebsocket(p.instrumentId, new BigNumber(p.price.value));
            }
            else
            {
                console.log('Non update message: ', message);
            }
        }
    },
    sendMessage(message)
    {
        if (wsstore.socketsConnected)
        {
            wsstore.socket.send(JSON.stringify(message));
        }
        else
        {
          console.log("Trying to send message ", message, "but not connected");
        }
    }
});

