<script setup lang="ts">
import Label from './elements/Label.vue';
import Token from './elements/Token.vue';
import Balance from './elements/Balance.vue';
import SwapControl from './elements/SwapControl.vue';
import PriceBlock from './elements/PriceBlock.vue';
import LimitBlock from './elements/LimitBlock.vue';
import TradeButton from './elements/TradeButton.vue';
import SwapPopup from './elements/SwapPopup.vue';
import LeverageBlock from "./elements/LeverageBlock.vue";

import {store} from '../store/Store';
import {sendTransaction} from "../lib/Transaction";
import {connectWallet} from "../lib/Wallet";
import {ref, watch} from "vue";
import BigNumber from "bignumber.js";
import {getDecimalFromDps} from '../utils/commonUtils'

const props = defineProps<{ title: string, extraClass: string, isDtw: boolean }>();

const swapButtonReady = ref(false);
const swapButtonTxt = ref('Loading...');
const swapModalVisible = ref(false);
const whitelistModalVisible = ref(false);

watch(() => store.onboardPollCount, () => {
  swapButtonText();
});
watch(() => store.walletIsConnected, () => {
  swapButtonText();
});
watch(() => store.rateLastUpdate, () => {
  swapButtonText();
});
watch(() => store.frontAllowance, () => {
  swapButtonText();
});

const swapButtonText = () => {
  if (store.rateLastUpdate < Date.now() - 20_000)
  {
    swapButtonReady.value = false;
    swapButtonTxt.value = 'Instrument Suspended';
  }
  else if (store.walletIsConnected)
  {
    var dpBackToken = store.tokenDPs[store.backToken];
    if (store.buyAmount.lt(getDecimalFromDps(dpBackToken)))
    {
      swapButtonReady.value = false;
      swapButtonTxt.value = 'Invalid Amount';
      return;
    }

    if ((store.frontBalance.gte(store.sellAmount)) || (props.isDtw && store.frontDtwBalance && store.frontDtwBalance.gte(store.sellAmount)))
    {
      if (store.tokenAddresses[store.frontToken] == '0x0000000000000000000000000000000000000000')
      {
        if ((store.liquidityBalances[store.backToken] && store.liquidityBalances[store.backToken].gte(store.buyAmount)) || store.leverage > 1)
        {
          swapButtonReady.value = true;
          swapButtonTxt.value = 'Confirm Swap';
        }
        else
        {
          swapButtonReady.value = false;
          swapButtonTxt.value = 'Not Enough Chain Liquidity';
        }
      }
      else
      {
        if (store.frontAllowance.gte(store.sellAmount) || props.isDtw)
        {
          if (store.liquidityBalances[store.backToken].gte(store.buyAmount) || store.leverage > 1)
          {
            swapButtonReady.value = true;
            swapButtonTxt.value = 'Confirm Swap';
          }
          else
          {
            swapButtonReady.value = false;
            swapButtonTxt.value = 'Not Enough Chain Liquidity';
          }
        }
        else
        {
          swapButtonReady.value = true;
          swapButtonTxt.value = 'Authorise Swap';
        }
      }
    }
    else
    {
      swapButtonReady.value = false;
      swapButtonTxt.value = 'Insufficient Funds';
    }
  }
  else
  {
    swapButtonReady.value = true;
    swapButtonTxt.value = 'Connect Wallet';
  }
}

const setBalancePercent = (percent: BigNumber) => {
  if (store.frontToken == "ETH")
  {
    const amountToSell: BigNumber = store.frontBalance.multipliedBy(percent).decimalPlaces(18);
    // You need to pay gas so you cant sell all your Eth
    const minEthForGas = 0.005;
    if ((store.frontBalance.minus(amountToSell)).lt(minEthForGas))
    {
      store.sellAmount = BigNumber.max(0, store.frontBalance.minus(minEthForGas));
    }
    else
    {
      store.sellAmount = amountToSell;
    }
  }
  else
  {
    store.sellAmount = store.frontBalance.multipliedBy(percent).decimalPlaces(store.tokenDPs[store.frontToken]);
  }
}


const swapButtonClick = () => {
  if (swapButtonReady.value)
  {
    if (store.walletIsConnected)
    {
      if (store.isWhitelisted)
      {
        if ((store.frontAllowance.gt(store.sellAmount) || store.tokenAddresses[store.frontToken] == '0x0000000000000000000000000000000000000000') || props.isDtw)
        {
          // Button is for sending transaction
          sendTransaction(props.isDtw);
        }
        else
        {
          // Button is confirm swap
          swapModalVisible.value = true;
        }
      }
      else
      {
        whitelistModalVisible.value = true;
      }
    }
    else
    {
      connectWallet();
    }
  }
}
</script>

<template>
  <div :class="['container-swaps-panel',props.extraClass]">
    <div v-if="props.title">
      <b>{{ props.title }}</b>
    </div>
    <div class="container-block ">
      <Label id="swap-block-you-sell" title="You sell" extra-class=""></Label>
      <div class="amount-buttons">
        <o-button label="50%" rounded outlined @click="setBalancePercent(new BigNumber(0.5))"/>
        <o-button label="100%" rounded outlined @click="setBalancePercent(new BigNumber(1))"/>
      </div>
      <Token title="Sell token" :token="store.frontToken" :amount="new BigNumber(store.sellAmount)" which="front" :ableToEditValue="true" extra-class=""></Token>
    </div>
    <Balance title="Balance:" :token="store.frontToken" :amount="isDtw ? new BigNumber(store.frontDtwBalance) : new BigNumber(store.frontBalance)" which="front" extra-class=""></Balance>
    <SwapControl title="Invert symbols" extra-class=""></SwapControl>
    <Label id="swap-block-you-get" title="You get" extra-class=""></Label>
    <div class="container-block ">
      <Token title="Buy token" :token="store.backToken" :amount="new BigNumber(store.buyAmount)" :ableToEditValue="false" which="back" extra-class=""></Token>
    </div>
    <Balance title="Balance:" :token="store.backToken" :amount="isDtw ? new BigNumber(store.backDtwBalance) : new BigNumber(store.backBalance)" which="back" extra-class=""></Balance>
    <PriceBlock title="Price" :frontToken="store.frontToken" :frontAmount="store.frontAmount ? new BigNumber(store.frontAmount) : new BigNumber(0)" :backToken="store.backToken"
                :backAmount="new BigNumber(store.displayRate)" extra-class=""></PriceBlock>
    <LimitBlock title="Slippage Tolerance" extra-class=""></LimitBlock>
    <LeverageBlock title="Leverage" v-if="store.maxLeverage > 1" extra-class=""></LeverageBlock>

    <TradeButton :title="swapButtonTxt" @click="swapButtonClick()" :enabled="swapButtonReady" extra-class=""></TradeButton>
    <o-modal v-model:active="whitelistModalVisible" class="alice-swap-popup-modal" width="300px" mobileBreakpoint="960px" scroll="clip">
      <swap-popup v-if="whitelistModalVisible" :title="swapButtonTxt" @close="whitelistModalVisible = false" v-bind:is-dtw="false"></swap-popup>
    </o-modal>
    <o-modal v-model:active="swapModalVisible" class="alice-swap-popup-modal" width="300px" mobileBreakpoint="960px" scroll="clip">
      <swap-popup v-if="swapModalVisible" :title="swapButtonTxt" @close="swapModalVisible = false" v-bind:is-dtw="false"></swap-popup>
    </o-modal>
  </div>
</template>

<style scoped>
.container-block {
  margin-top: 10px;
  text-align: left;
}

#swap-block-you-sell {
  display: inline-block;
}

.amount-buttons {
  float: right;
}

.amount-button {
  background: var(--button-background-colour);
  border-color: var(--button-background-colour);
  margin: 1px;
}

@media (max-width: 959px) {
  .container-block {
    margin-top: 0.2dvh;
    text-align: left;
  }
}

@media (max-width: 330px) {
  .container-swaps-panel {
    margin-top: 0px;
  }
}
</style>
